import styled from "styled-components";

export const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  justify-content: center;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
`;

export const Icon = styled.i`
  display: ${props => props.display ? 'block' : 'none'};
  color: ${props => props.color || "#fff"};
  cursor: pointer;
`;

export const TableCellContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
