import { FIELDS } from "../constants/fields";
import { HeaderContentWrapper, Icon, IconsContainer } from "../Orders.styled";
import { handleSort } from "./handleSort";

export const getHeaders = ({
  sortings, setSortings, setData, data
}) => [
  <HeaderContentWrapper
    onClick={() => {
      const nextValue = sortings[FIELDS.COUNTRY] === 0 ? 1 : sortings[FIELDS.COUNTRY] === 1 ? -1 : 1;

      setSortings({
        ...sortings,
        [FIELDS.COUNTRY]: nextValue,
      });
      setData(handleSort(FIELDS.COUNTRY, nextValue, data));
    }}
  >
    Country 
    <IconsContainer >
      <Icon display={sortings[FIELDS.COUNTRY] === 1} className="fa fa-sort-up" /> 
      <Icon display={sortings[FIELDS.COUNTRY] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.CITY] === 0 ? 1 : sortings[FIELDS.CITY] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.CITY]: nextValue,
    });
    setData(handleSort(FIELDS.CITY, nextValue, data));
  }}>
    City
    <IconsContainer >
      <Icon display={sortings[FIELDS.CITY] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.CITY] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.POSTAL_CODE] === 0 ? 1 : sortings[FIELDS.POSTAL_CODE] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.POSTAL_CODE]: nextValue,
    });
    setData(handleSort(FIELDS.POSTAL_CODE, nextValue, data));
  }}>
    Postal code
    <IconsContainer >
      <Icon display={sortings[FIELDS.POSTAL_CODE] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.POSTAL_CODE] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.STREET] === 0 ? 1 : sortings[FIELDS.STREET] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.STREET]: nextValue,
    });
    setData(handleSort(FIELDS.STREET, nextValue, data));
  }
  }>
    Street
    <IconsContainer >
      <Icon display={sortings[FIELDS.STREET] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.STREET] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.FULL_NAME] === 0 ? 1 : sortings[FIELDS.FULL_NAME] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.FULL_NAME]: nextValue,
    });
    setData(handleSort(FIELDS.FULL_NAME, nextValue, data));
  }}
  >
    Full name
    <IconsContainer >
      <Icon display={sortings[FIELDS.FULL_NAME] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.FULL_NAME] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.PHONE] === 0 ? 1 : sortings[FIELDS.PHONE] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.PHONE]: nextValue,
    });
    setData(handleSort(FIELDS.PHONE, nextValue, data));
  }
  }>
    Phone
    <IconsContainer >
      <Icon display={sortings[FIELDS.PHONE] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.PHONE] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.EMAIL] === 0 ? 1 : sortings[FIELDS.EMAIL] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.EMAIL]: nextValue,
    });
    setData(handleSort(FIELDS.EMAIL, nextValue, data));
  }
  }>
    Email
    <IconsContainer >
      <Icon display={sortings[FIELDS.EMAIL] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.EMAIL] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.DELIVERY_DATE] === 0 ? 1 : sortings[FIELDS.DELIVERY_DATE] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.DELIVERY_DATE]: nextValue,
    });
    setData(handleSort(FIELDS.DELIVERY_DATE, nextValue, data));
  }
  }>
    Delivery date
    <IconsContainer >
      <Icon display={sortings[FIELDS.DELIVERY_DATE] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.DELIVERY_DATE] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.PRICE] === 0 ? 1 : sortings[FIELDS.PRICE] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.PRICE]: nextValue,
    });
    setData(handleSort(FIELDS.PRICE, nextValue, data));
  }
  }>
    Price
    <IconsContainer >
      <Icon display={sortings[FIELDS.PRICE] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.PRICE] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  <HeaderContentWrapper onClick={() => {
    const nextValue = sortings[FIELDS.VARIANTS] === 0 ? 1 : sortings[FIELDS.VARIANTS] === 1 ? -1 : 1;

    setSortings({
      ...sortings,
      [FIELDS.VARIANTS]: nextValue,
    });
    setData(handleSort(FIELDS.VARIANTS, nextValue, data));
  }
  }>
    Variants
    <IconsContainer >
      <Icon display={sortings[FIELDS.VARIANTS] === 1} className="fa fa-sort-up" />
      <Icon display={sortings[FIELDS.VARIANTS] === -1} className="fa fa-sort-down"/>
    </IconsContainer>
  </HeaderContentWrapper>,
  "Options",
];
