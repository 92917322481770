export const FIELDS = {
  COUNTRY: "country",
  CITY: "city",
  POSTAL_CODE: "postal_code",
  STREET: "street",
  FULL_NAME: "full_name",
  PHONE: "phone",
  EMAIL: "email",
  DELIVERY_DATE: "suggested_delivery_date",
  PRICE: "price",
  VARIANTS: "variants",
};
