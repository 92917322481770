import moment from "moment";
import { Colors } from '../../../common/colors';
import { countPrice, getOrderItems } from "./orderCalculations";
import { handleEditOrderAndItems, handleOpenGMAP, handleOpenGSEARCH, handleSeeInCrm, handleSeeInCrmContact, handleSuspiciousPrice, onChangeSelected } from "./actionHandlers";
import { Icon, TableCellContent } from "../Orders.styled";

export const getRaws = ({
  data,
  selectedParcels,
  handleVerify,
  setEditShow,
  setEditData,
  selectedProduct,
  config,
  setSelectedParcels,
  handleSaveOrder
}) => data?.map((order) => (
  <tr key={order._id}>
    <td>
      {order.country} 
    </td>
    <td>
      <TableCellContent>
        {order.city} <Icon onClick={() => handleSaveOrder({
          text: order.city,
          type: "city",
          order,
        })} display color={Colors.darkBlue} className="fa fa-rotate" />
      </TableCellContent>
    </td>
    <td>
      {order.postal_code}
    </td>
    <td>
      <TableCellContent>
        {order.street} <Icon onClick={() => handleSaveOrder({
          text: order.street,
          type: "street",
          order,
        })} display color={Colors.darkBlue} className="fa fa-rotate" />
      </TableCellContent>
    </td>
    <td>
      <TableCellContent>
        {order.additional_info && order.additional_info.length > 0
          ? order.full_name + ", " + order.additional_info
          : order.full_name}
        <Icon onClick={() => handleSaveOrder({
          text: order.full_name,
          type: "full_name",
          order,
        })} display color={Colors.darkBlue} className="fa fa-rotate" />
      </TableCellContent>
    </td>
    <td>
      {(order.phone?.courier || order.phone?.default)
        ?.replaceAll(' ', '')
        ?.match(/.{1,3}/g)
        ?.join("-")}
    </td>
    <td>{order.email}</td>
    <td>{moment(order.suggested_delivery_date).format("YYYY-MM-DD")}</td>
    <td
      style={{
        background: handleSuspiciousPrice(order, config, selectedProduct)
          ? Colors.red
          : Colors.green,
      }}
    >
      {countPrice(getOrderItems(order))}
      {" " + (order.shipping?.currency || order.currency)}
    </td>
    <td>
      {getOrderItems(order).map((item, i) => (
        <div key={i}>
          {item.product_variant} {item.price}{" "}
          {order.shipping?.currency || order.currency}
        </div>
      ))}
    </td>
    <td>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          checked={selectedParcels.includes(order._id)}
          type="checkbox"
          onClick={(e) => {
            onChangeSelected(order._id, e.target.checked, setSelectedParcels);
          }}
          style={{
            flexShrink: 0,
            width: "30px",
            height: "30px",
            alignSelf: "center",
          }}
        />
        <div
          style={{
            minHeight: "100px",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i
            className="fa fa-map animation-scale tooltip-custom"
            style={{
              fontSize: "20px",
              color: Colors.darkBlue,
              cursor: "pointer",
              marginLeft: '8px'
            }}
            onClick={(e) => handleOpenGMAP(e, order)}
          >
            <span className="tooltiptext-custom">Go to GMAP</span>
          </i>
          <i
            className="fa fa-search animation-scale tooltip-custom"
            style={{
              fontSize: "20px",
              color: Colors.darkBlue,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenGSEARCH(e, order)}
          >
            <span className="tooltiptext-custom">Search full name</span>
          </i>

          <i
            className="fa fa-edit animation-scale tooltip-custom"
            style={{
              fontSize: "20px",
              color: Colors.yellow,
              cursor: "pointer",
            }}
            onClick={(e) =>
              handleEditOrderAndItems(
                order._id,
                order.shipping?.currency || order.currency,
                setEditData,
                setEditShow
              )
            }
          >
            <span className="tooltiptext-custom">Edit order</span>
          </i>
          <i
            className="fa fa-address-card animation-scale tooltip-custom"
            style={{
              fontSize: "20px",
              color: Colors.yellow,
              cursor: "pointer",
            }}
            onClick={(e) => handleSeeInCrm(e, order._id)}
          >
            <span className="tooltiptext-custom">Go CRM Order</span>
          </i>
          <i
            className="fa  fa-address-book animation-scale tooltip-custom"
            style={{
              fontSize: "20px",
              color: Colors.yellow,
              cursor: "pointer",
            }}
            onClick={(e) => handleSeeInCrmContact(e, order.contact)}
          >
            <span className="tooltiptext-custom">Go CRM contact</span>
          </i>
          <i
            className="fa fa-check animation-scale tooltip-custom"
            style={{
              fontSize: "20px",
              color: "green",
              cursor: "pointer",
            }}
            onClick={(e) => handleVerify(e, order)}
          >
            <span className="tooltiptext-custom">Verify address</span>
          </i>
        </div>
      </div>
    </td>
  </tr>
));
