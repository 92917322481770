import { countPrice } from "./orderCalculations";

export const handleSeeInCrm = (e, _id) => {
  e.preventDefault();
  const crmLink = `https://crm.eou-edu.eu/dashboard/order/` + _id;
  window.open(crmLink, "_blank").focus();
};

export const handleOpenGSEARCH = (e, order) => {
  e.preventDefault();
  const gSearchLink = `https://www.google.com/search?q=${order.full_name.replaceAll(
    " ",
    "+"
  )}`;
  window.open(gSearchLink, "_blank").focus();
};

export const handleOpenGMAP = (e, order) => {
  e.preventDefault();
  const gMapsLink = `https://www.google.com/maps?q=${order.country},${order.city},${order.street},${order.postal_code}`;
  window.open(gMapsLink, "_blank").focus();
};



export const handleSeeInCrmContact = (e, _id) => {
  e.preventDefault();
  const crmLink = `https://crm.eou-edu.eu/dashboard/contact/` + _id;
  window.open(crmLink, "_blank").focus();
};

export const handleSuspiciousPrice = (data, config, selectedProduct) => {
  const price = countPrice(data.order_items);
  const minPrice = config
    .find((market) => market.country.value === data.country)
    ?.products.find(
      (product) => product.value === selectedProduct.value
    )?.minPrice;

  if (price < minPrice) {
    return true;
  }

  return false;
};

export const handleEditOrderAndItems = (id, currency, setEditData, setEditShow) => {
  setEditData(() => ({ id, currency }));
  setEditShow(() => true);
};

export const onChangeSelected = (id, state, setSelectedParcels) => {
  if (state) setSelectedParcels((prev) => [...prev, id]);
  else
    setSelectedParcels((prev) => [...prev.filter((parcel) => parcel !== id)]);
};
